import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Table",
  "type": "Content",
  "icon": "designGridLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Table`}</strong>{` component used to render a table.`}</p>
    <hr></hr>
    <h1>{`Examples`}</h1>
    <h2>{`Simple table`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<Table>
  <TableHead>
    <TableHeadRow>
      <TableHeadCell align="left">No.</TableHeadCell>
      <TableHeadCell>First Name</TableHeadCell>
      <TableHeadCell>Last Name</TableHeadCell>
      <TableHeadCell align="right">Age</TableHeadCell>
    </TableHeadRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell align="left">1</TableCell>
      <TableCell important>John</TableCell>
      <TableCell>Doe</TableCell>
      <TableCell align="right" highlight>
        19
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">2</TableCell>
      <TableCell important>Thomas</TableCell>
      <TableCell>Edison</TableCell>
      <TableCell align="right" highlight>
        55
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">3</TableCell>
      <TableCell important>Abraham</TableCell>
      <TableCell>Lincoln</TableCell>
      <TableCell align="right" highlight>
        45
      </TableCell>
    </TableRow>
  </TableBody>
</Table>
`}</code></pre>
    <h2>{`Stocks table`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`() => {
  const [priceUp, setPriceUp] = React.useState(250);
  const [priceDown, setPriceDown] = React.useState(250);
  const [percentUp, setPercentUp] = React.useState(-100);
  const [percentDown, setPercentDown] = React.useState(100);

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setPriceUp(priceUp + 1);
    }, 1900);
    return () => clearInterval(timeout);
  }, [priceUp, setPriceUp]);

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setPriceDown(priceDown - 1);
    }, 1400);
    return () => clearInterval(timeout);
  }, [priceDown, setPriceDown]);

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setPercentDown(percentDown - 0.01);
    }, 1600);
    return () => clearInterval(timeout);
  }, [percentDown, setPercentDown]);

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setPercentUp(percentUp + 0.01);
    }, 1700);
    return () => clearInterval(timeout);
  }, [percentUp, setPercentUp]);

  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          <TableHeadCell align="left">Ticker</TableHeadCell>
          <TableHeadCell align="right">Price</TableHeadCell>
          <TableHeadCell align="right">Change</TableHeadCell>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left">
            <SymbolBadge
              ticker="APPL"
              name="Apple"
              image="https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AAPL.png"
            />
          </TableCell>
          <TableCell align="right">
            <Price amount={priceUp} currency="USD" />
          </TableCell>
          <TableCell align="right">
            <Percent amount={percentUp} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <SymbolBadge
              ticker="TSLA"
              name="Tesla"
              image="https://storage.googleapis.com/iexcloud-hl37opg/api/logos/TSLA.png"
            />
          </TableCell>
          <TableCell align="right">
            <Price amount={priceDown} currency="USD" />
          </TableCell>
          <TableCell align="right">
            <Percent amount={percentDown} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <SymbolBadge
              ticker="MSFT"
              name="Microsoft"
              image="https://storage.googleapis.com/iexcloud-hl37opg/api/logos/MSFT.png"
            />
          </TableCell>
          <TableCell align="right">
            <Price amount={priceUp} currency="USD" />
          </TableCell>
          <TableCell align="right">
            <Percent amount={percentUp} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <SymbolBadge
              ticker="FB"
              name="Facebook"
              image="https://storage.googleapis.com/iexcloud-hl37opg/api/logos/FB.png"
            />
          </TableCell>
          <TableCell align="right">
            <Price amount={priceDown} currency="USD" />
          </TableCell>
          <TableCell align="right">
            <Percent amount={percentDown} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <SymbolBadge
              ticker="AMZN"
              name="Amazon"
              image="https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AMZN.png"
            />
          </TableCell>
          <TableCell align="right">
            <Price amount={priceUp} currency="USD" />
          </TableCell>
          <TableCell align="right">
            <Percent amount={percentUp} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <SymbolBadge
              ticker="NFLX"
              name="Netflix"
              image="https://storage.googleapis.com/iexcloud-hl37opg/api/logos/NFLX.png"
            />
          </TableCell>
          <TableCell align="right">
            <Price amount={priceDown} currency="USD" />
          </TableCell>
          <TableCell align="right">
            <Percent amount={percentDown} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
`}</code></pre>
    <h1>{`Props `}<inlineCode parentName="h1">{`Table`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Table`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`table`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Props `}<inlineCode parentName="h1">{`TableHead`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableHead`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`thead`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Props `}<inlineCode parentName="h1">{`TableHeadRow`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableHeadRow`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`tr`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Props `}<inlineCode parentName="h1">{`TableHeadCell`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableHeadCell`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`th`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "center" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aligns the content to a side`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Props `}<inlineCode parentName="h1">{`TableBody`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableBody`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`tbody`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Props `}<inlineCode parentName="h1">{`TableRow`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableRow`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`tr`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`Props `}<inlineCode parentName="h1">{`TableCell`}</inlineCode></h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`TableCell`}</strong>{` passes all props to the underlying `}<inlineCode parentName="p">{`td`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "center" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aligns the content to a side`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`important`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the text as important`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`highlight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the text as highlighted`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      